class Elementor_Give_Campain {
    static instance;

    static getInstance() {
        if (!Elementor_Give_Campain.instance) {
            Elementor_Give_Campain.instance = new Elementor_Give_Campain();
        }
        return Elementor_Give_Campain.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-give-campain.default', ($scope) => {
            let $wrap = $scope.find('.carousel-activate');
            let $carousel = $scope.find('.carousel-activate .give-grid');
            if ($carousel.length > 0) {
                $carousel.removeClass(function (index, css) {
                    return (css.match(/\bgive-grid--\S+/g) || []).join(' ');
                }).addClass('owl-carousel owl-theme');
                let data = $wrap.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
        });
    }
}

Elementor_Give_Campain.getInstance();