class Elementor_Give_Form{
    static instance;

    static getInstance() {
        if (!Elementor_Give_Form.instance) {
            Elementor_Give_Form.instance = new Elementor_Give_Form();
        }
        return Elementor_Give_Form.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-give-form.default', ( $scope ) => {
            // Code Here

        } );
    }
}
Elementor_Give_Form.getInstance();